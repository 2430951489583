// extracted by mini-css-extract-plugin
export var noPadding = "v_fj d_fj";
export var videoFrameWrapper = "v_r6 d_kS d_b5";
export var videoIframeStyle = "v_pS d_d5 d_w d_H d_by d_b1 d_R";
export var videoLayoutRight = "v_r7 d_kR d_bK";
export var videoInnerWrapper = "v_r8 d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5";
export var videoText = "v_r9 d_dw d_w";
export var videoInnerInnerWrapper = "v_sb d_bz d_bD d_bJ";
export var btnWrapper = "v_d2 d_w d_bz d_bD d_cj";
export var btn = "v_mB d_b1 d_bz d_bD d_bP d_cH d_b6 d_dB d_n x_s6 x_sK";
export var icon = "v_p9 d_cC";
export var textLeft = "v_dv";
export var textCenter = "v_dw";
export var textRight = "v_dx";